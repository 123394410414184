/*---------------------------------------------
ONE COLUMN LIST
---------------------------------------------*/
.onecolumn_list {
    margin: 30px 0;
    @include max-screen(1200px) {
      margin: 10px 0 30px;
    }
    @include SP{
        margin: 20px 0 30px;
    };

    > li {
      border-top: #cccccc solid 1px;
    //   &:nth-child(4) {
    //     border-bottom: #cccccc solid 1px;
    //   }
      &:last-child {
        border-bottom: #cccccc solid 1px;
      }
      > a {
        display: block;
        padding: 10px 0 10px 15px;
        position: relative;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        @include SP{
            padding: 10px 0px 10px 27px;
        }
        &:after {
          content: "";
          width: 8px;
          height: 8px;
          position: absolute;
          top: 50%;
          right: 20px;
          -webkit-transform: rotateZ(-45deg) translateY(-50%);
          transform: rotateZ(-45deg) translateY(-50%);
          border-right: 1px solid #000;
          border-bottom: 1px solid #000;
        }

        @include PC{
            &:hover {
              background-color: #ccd9eb;
            }
        }
      }
      &.pdf{
          > a{
            &:after{
                content: '\f1c1' !important;
                font-family: 'Font Awesome 5 Free';
                font-weight: 300;
                font-size: 16px;
                margin-left: 5px;
                color: #000;
                -webkit-transform: rotateZ(0) translateY(0);
                transform: rotateZ(0) translateY(0);
                border: none;
                top: 40%;
                @include SP{
                    top: 50%;
                }
            }
          }
      }
    }

    &_date {
      font-size: 14px;
      letter-spacing: 0.04em;
      margin-right: 5px;
    }

    p {
      font-size: 14px;
      line-height: 1.642857143em;
      letter-spacing: 0.04em;
      margin-top: 5px;
      padding-right: 50px;
      @include SP{
          padding-right: 40px;
          line-height: 1.571428571em;
      }
    }
    &.movie_list{
      font-size: 15px;
      @include SP {
        font-size: 14px;  
      }
      .li_title{
        font-weight: bold;
        display: block;
        padding: 15px 30px 5px 15px;
        @include SP {
          padding: 20px 30px 5px 27px;          
        }
      }
      a{
        cursor: pointer;
        font-weight: normal;
        display: block;
        padding: 10px 0 10px 15px;
        position: relative;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        @include SP{
          padding: 10px 0px 10px 27px;
        }
        &:after {
          content: "";
          width: 8px;
          height: 8px;
          position: absolute;
          top: 50%;
          right: 20px;
          -webkit-transform: rotateZ(-45deg) translateY(-50%);
          transform: rotateZ(-45deg) translateY(-50%);
          border-right: 1px solid #000;
          border-bottom: 1px solid #000;
        }
        @include PC{
          &:hover {
            background-color: #ccd9eb;
          }
        }
      }
      .movie_list_child{
        counter-reset: item;
        margin-bottom: 10px;
        li{
          position: relative;
          &::before{
            counter-increment: item;
            content: counter(item)'.';
            position: absolute;
            top: 5px;
            left: 17px;
            z-index: 2;
            @include SP {
              left: 29px;  
            }
          }
          a{
            padding: 5px 35px 5px 35px;
            cursor: pointer;
            @include SP{
              padding: 5px 35px 5px 50px;
            }    
          }
        }
      }

    }
}