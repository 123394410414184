#movie_box{
    display: none;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.75);
    z-index: 99999;
    top: 0;
    left: 0;
    position: fixed;
    .movie_flex{
      display: flex;
      width: 100%;
      height: 100vh;
      justify-content: center;
      align-items: center;
      .movie_cvr{
        display: block;
        position: relative;
        width: calc(100% - 60px);
        max-width: 640px;
        &::after{
          content: "";
          width: 100%;
          padding-top: 56.25%;
          display: block;
        }
        #movie{
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
        }
      }
      .movie_close{
        width: 40px;
        height: 40px;
        color: #fff;
        position: absolute;
        top: -50px;
        right: 0;
        cursor: pointer;
        &::before,&::after{
          content: '';
          height: 3px;
          width: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          background: #fff;
        }
        &::before{
          transform: rotate(-45deg);
        }
        &::after{
          transform: rotate(45deg);
        }
      }
  
    }
  }