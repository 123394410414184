/*---------------------------------------------
Button
---------------------------------------------*/
.button{
    @include SP{
        text-align: center;
    };

    > a, >span{
        display: inline-block;
        width: 160px;
        height: 40px;
        border: #FFF solid 1px;
        text-align: center;
        font-size: 14px;
        padding-top: 10px;
        position: relative;
        @include ie{
            padding-top: 11px;
        };

        @include SP{
            max-width: 200px;
            width: 100%;
            height: 38px;
            padding-top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            display: -webkit-flex;
            -webkit-justify-content: center;
            -webkit-align-items: center;
        };


        @include PC{
            transition: all .3s;
            &:hover{
                background-color: #FFF;
                color: #000 !important;
            }
        };

        &:after{
            content     : '\f105';
            font-family : 'Font Awesome 5 Free';
            font-weight : 300;
            font-size: 24px;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);

            @include ie{
                top: 55%;
            }
        }
    }

    &.blue{
        > a {
            color: #235dac;
            border: #235dac solid 1px;

            @include PC{
                &:hover{
                opacity: 1;
                border: #235dac solid 1px;
                background-color: #235dac;
                color: #FFFFFF !important;
            }
            };
        }
    }
    &.white{
        > a {
			color: #FFF;
			background-color:#235dac;

            @include PC{
                &:hover{
				opacity: 1;
                background-color: #FFF;
                color: #235dac !important;
            }
            };
        }
    }
}
