@charset "utf-8";
@import "../mixin";


@mixin flex($align: 1, $justify: 1){
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	@if($align==1){
		align-items: center;
	}
	@if($justify==1){
		justify-content: center;
	}
}

$NotoSans : 'Noto Sans JP', sans-serif;
$Meiryo : Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;

@font-face {
    font-family: 'A1Mincho';
    src: url(/1minute/font/A-OTF-A1MinchoStd-Bold.otf);
}

@mixin font($size: 14, $line: 30, $let: 0) {
	font-size: $size + px;
	line-height: $line + px;
	letter-spacing: $let + em;
}

// A-OTF-A1MinchoStd-Bold.otf
@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before{
		display: block;
		content: " ";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

@mixin posAbsTR($width, $height, $top, $right){
	position: absolute;
	content: '';
	width: $width;
	height: $height;
	top: $top;
	right: $right;
}

/*---------------------------------------------
LINK LIST 02
---------------------------------------------*/
.link_list02{
    *{
        font-family: $Meiryo;
    }
    padding-top: 10px;
    @include SP{
        padding: 0 20px;
        margin-bottom: 27px;
    }
    .boxs{
        background-color: #fff;
        padding: 30px 34px;
        margin-top: 17px;
        @include SP{
            margin-top: 32px;
            padding: 34px 20px 20px;
        }
        .lst{
            @include flex(0,0);
            justify-content: space-between;
            margin-bottom: 30px;
            flex-wrap: wrap;
            @include SP{
                margin-bottom: 11px;
            }
            .item{
                width: calc(25% - 21px);
                background-color: #fff;
                box-shadow: 0 0 10px rgba(0,0,0,0.5);
                @include flex(0,0);
                @include SP{
                    width: calc(50% - 12px);
                    margin-bottom: 18px;
                }
                .content{
                    padding: 120px 7px 39px;
                    background: center top 28px/80px 80px no-repeat;
                    width: 100%;
                    display: block;
                    cursor: pointer;
                    @include SP{
                        padding: 64px 7px 29px;
                        background: center top 15px/44px 44px no-repeat;
                    }
                    .ttl, .txt{
                        text-align: center;
                    }
                    .ttl{
                        font-family: $NotoSans;
                        @include font(20,46,0);
                        font-weight: 500;
                        @include SP{
                            @include font(12,25,0);
                        }
                    }
                    .txt{
                        @include font(14,20,0.04);
                        margin-top: -5px;
                        @include SP{
                            @include font(10,14,0.04);
                            margin-top: 0px;
                        }
                    }
                    &--01{
                        background-image: url(/assets/images/1minute/ico01.png);
                    }
                    &--02{
                        background-image: url(/assets/images/1minute/ico02.png);
                    }
                    &--03{
                        background-image: url(/assets/images/1minute/ico03.png);
                    }
                    &--04{
                        background-image: url(/assets/images/1minute/ico04.png);
                    }
                }
            }
        }
        .lst01{
            @include flex(1,0);
            justify-content: space-between;
            flex-wrap: wrap;
            .item{
                width: calc(50% - 14px);
                background-color: #fff;
                box-shadow: 0 0 10px rgba(0,0,0,0.5);
                @include flex(0,0);
                @include SP{
                    width: 100%;
                    margin-bottom: 13px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                a{
                    @include flex(0, 0);
                    width: 100%;
                }
                .img, &__ttl{
                    flex: 1 0 auto;
                    @include flex(1,1);
                }
                .img{
                    @include aspect-ratio(284,160);
                    // width: calc(100% - 255px);
                    width: 52.8%;
                }
                &__ttl{
                    // width: 255px;
                    width: 47.2%;
                    text-align: center;
                    @include font(20,46,0);
                    .new-tab{
                        position: relative;
                        &:after{
                            @include posAbsTR(15px,14px,calc(50% - 7px), -20px);
                            background: url(/assets/images/1minute/ico_newtab.png) center/cover no-repeat;
                            @include SP{
                                @include posAbsTR(8px,8px,calc(50% - 5px), -12px);
                            }
                        }
                    }
                    @include SP{
                        @include font(12,25,0);
                    }
                }
            }
            .the-arrow{
                &:before, &:after{
                    @include SP{
                        display: none;
                    }
                }
            }
        }
    }
}
