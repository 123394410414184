.login {
	&_title {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 11px;
	}

	&_text {
		font-size: 14px;
		line-height: 1.857142857;
		margin-bottom: 15px;
	}

	&_label {
		display: block;
		margin-bottom: 10px;
		font-size: 14px;

		span {
			display: block;
		}
	}

	&_input {
		height: 22px;
		max-width: 263px;
		width: 100%;
		border: 1px solid #dcdfe6;
		border-top-color: #aaadb2;
		border-radius: 2px;
		margin-top: 7px;
		padding: 0 6px;
		font-size: 14px;
	}

	&_error {
		display: none;

		&-message {
			font-size:14px;
		}
	}

	&_btn {
			display: block;
			max-width: 290px;
			height: 60px;
			width: 100%;
			background-color: #0269b2;
			text-align: center;
			font-size: 18px;
			font-weight: bold;
			color: #FFF;
			position: relative;
			margin: 15px auto 0;
			transition: all .3s;
			&:hover{
				background-color: #FFF;
				border: #0269b2 solid 1px;
				color: #0269b2;
				&:after{
					color: #0269b2;
				}
			}
		
			@include ie{
				padding-top: 4px;
			};
		
			@include SP{
				max-width: 295px;
			};
		
		
			&:after{
				content: '\f105';
				font-family: 'Font Awesome 5 Free';
				font-weight: 300;
				font-size: 20px;
				color: #FFF;
				position: absolute;
				top: 50%;
				right: 15px;
				transform: translateY(-50%);
			}
		}
}