.modal{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90000;
    display: none;

    &_bg{
        background: rgba(#000, .6);
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
    }

    &_outer{
        position: absolute;
        top: 50%;
        left: calc(50% + 120px);
        transform: translate(-50%,-50%);
        padding-top: 40px;
        @include ie{
            left: calc(50% + 110px);
        };
        @include max-screen(1200px){
            width: calc(100% - 40px);
            height: calc(100% - 150px);
            top: 100px;
            left: 50%;
            transform: translateX(-50%);
            padding-top: 0;
            overflow-y: auto;
        }
    }

    .ms1200_pc{
        @include max-screen(1200px) {
            display: none !important;
        }
    }
    .ms1200_sp{
        @include min-screen(1200px){
            display: none !important;
        }
    }

    &_close{
        width: 30px;
        height: 30px;
        background-color: #FFF;
        position: absolute;
        right: 0;
        top: 0;
        transition: all .3s;
        cursor: pointer;
        @include max-screen(1200px){
            right: 20px;
            top: 55px;
        };

        &:before{
            content: '\f00d';
            color: #0269b2;
            font-family: 'Font Awesome 5 Free';
            font-weight: 300;
            font-size: 35px;
            position: absolute;
            left: 50%;
            top: 50%;
			transform: translateX(-50%) translateY(-50%);
			
			@include ie{
				padding-top:5px;
			}
		}

        &:hover{
            opacity: .7;
        }
    }

    &_contents{
        background-color: #FFF;
        padding: 30px;
        width: 875px;
        margin: 0 auto;
        @include max-screen(1200px){
            width: 100%;
            padding: 15px 20px 25px;

        };
	}
}
