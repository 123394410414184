/*---------------------------------------------
Category
---------------------------------------------*/
.category {
    font-size: 13px;
    letter-spacing: 0.04em;
    display: inline-block;
    color: #fff;
    min-width: 120px;
    text-align: center;
    margin-right: 10px;
    padding: 1px 5px 0px;

    @include SP{
        min-width: 100px;
        font-size: 12px;
        padding: 1px 5px 2px;

        &:last-of-type{
            margin-right: 0;
        }
    };

    &_other {
        background-color: #5f2500;
    }
    &_reply {
        border: #cc0000 solid 1px;
        color: #cc0000;
    }
    &_seminar, &_meeting {
        background-color: #235dac;
    }
    &_training {
        background-color: #440062;
    }
    &_workshop  {
        background-color: #00736d;
    }
    &_new {
        background-color: #333333;
    }
    &_news {
        background-color: #870404;
    }
}
