.article-list {
	border-top:1px solid #cccccc;

	&-link {
		display:block;
		border-bottom:1px solid #cccccc;
		padding:10px 50px 10px 14px;
		position: relative;
		transition:background-color 0.3s;
		&:after {
			content: '\f105';
			font-family: 'Font Awesome 5 Free';
			font-weight: 300;
			font-size: 20px;
			color: #000;
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
		}
		&:hover {
			background-color:#ccd9eb;
		}
	}

	&-date {
		font-size:14px;
		letter-spacing:0.04em;
		line-height:1;
		margin-right: 22px;
	}

	&-text {
		font-size:14px;
		letter-spacing:0.04em;
		line-height: 1.71;
		padding:10px 0 0;
	}

	.icon {
		&-pdf {
			&:after {
				content:'\f1c1';
				font-size:16px;
			}
		}
	}
}