/*---------------------------------------------
LINK LIST
---------------------------------------------*/
.link_list{
    display: block;
    &:after {
        content: "";
        display: block;
        clear: both;
    }

    > li{
        height: 180px;
        background-color: #fff;
        margin-top: 40px;
        display: block;
        position: relative;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        box-shadow: 0px 0px 10px rgba(#000, .2);
        padding: 0;
        width: 30.8%;
        float: left;
        margin-right: 3.8%;

        @include SP{
            height: 100px;
            margin-top: 20px;
            width: 47.4%;
            margin-right: 5.2%;

            &:nth-child(-n + 2) {
                margin-top: 0;
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
        };

        @include PC{
            &:hover {
                background-color: #ccd9eb;
            }

            &:nth-child(-n + 3) {
                margin-top: 0;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        };

        .icon {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background-color: #235dac;
            margin: 28px auto 15px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            color: #fff;
            font-family: "Font Awesome 5 Free";
            font-weight: 300;
            font-size: 40px;
            @include ie{
                padding: 5px 0 0 3px;
            };

            @include SP{
                width: 40px;
                height: 40px;
                font-size: 20px;
                margin: 15px auto 5px;
            };
        }
        span {
            font-size: 20px;
            line-height: 1.4em;
            text-align: center;
            border-bottom: none;
            display: block;
            font-family: 'Noto Sans JP', sans-serif;

            @include SP{
                font-size: 12px;
                line-height: 1.916666667em;
            };
            @include max-screen(1300px){
                font-size: 18px;
            };
            @include max-screen(1200px){
                font-size: 16px;
            };
            @include max-screen(1100px){
                font-size: 14px;
            };
            @include max-screen(1000px){
                font-size: 12px;
            };
        }
        .arrow {
            width: 20px;
            height: 20px;
            background-color: #fff;
            border: #235dac solid 1px;
            display: block;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            bottom: -10px;

            &:after {
            content: "";
            width: 7px;
            height: 7px;
            position: absolute;
            left: 4px;
            top: 50%;
            -webkit-transform: translateY(-50%) rotateZ(-45deg);
            transform: translateY(-50%) rotateZ(-45deg);
            border-right: 1px solid #235dac;
            border-bottom: 1px solid #235dac;
            }
        }
    }

    .oneminute_link{
        // width: calc(100% - (((100% - 60px) / 3) + 30px));
        width: 65%;
        margin-right: 0;
        @include SP{
            width: 100%;
            height: auto;
        };
        &:hover{
            background-color: transparent;
        }
        > a{
            height: 100%;
            width: 100%;
            display: flex;
            background-color: #fff;
            box-shadow: 0px 0px 10px rgba(#000, .2);
            @include SP{
                display: block;
                height: 200px;
                max-width: inherit;
            };
            @include PC{
                transition: all .3s;
                &:hover{
                    opacity: .7;
                }
            };
        }

        &_img{
            width: 50%;
            height: 100%;
            background-image: url(../images/top/training.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            @include SP{
                width: 100%;
                height: 70%;
            };
        }
        &_txt{
            width: 50%;
            height: 100%;
            position: relative;
            text-align: center;
            padding-top: 75px;
            @include SP{
                width: 100%;
                height: 30%;
                padding-top: 15px;
            };

            p{
                font-size: 20px;
                line-height: 1.5em;
                text-align: center;
                @include SP{
                    font-size: 18px;
                };
            }
            .arrow{
                width: 20px;
                height: 20px;
                background-color: #fff;
                border: #235dac solid 1px;
                display: block;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -10px;

                &:after {
                    content: "";
                    width: 7px;
                    height: 7px;
                    position: absolute;
                    left: 4px;
                    top: 50%;
                    transform: translateY(-50%) rotateZ(-45deg);
                    border-right: 1px solid #235dac;
                    border-bottom: 1px solid #235dac;
                }
            }
        }
    }
}
