.interview_link {
    display: flex;
    transition: opacity .3s;
    &:hover{
        opacity: .7;
    }

    @include SP{
        display: block;
        margin-top: 20px;
    };

    &_img {
        width: 100%;
        max-width: 250px;
        flex-shrink: 0;
        img {
            width: 100%;
        }
        @include SP{
            max-width: inherit;
        };
    }
    &_txt {
        padding-left: 30px;
        @include SP{
            padding-left: 0;
            padding-top: 15px;
        };
        time{
            font-size: 12px;
            letter-spacing: 0.04em;
            color: #666666;
        }
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.04em;
            line-height: 1.277777778em;
            padding-bottom: 10px;
            border-bottom: #235dac solid 1px;
            display: block;

            @include SP{
                font-size: 16px;
                line-height: 1.375em;
                padding: 8px 0 16px;
            };

            &:after{
                content: '\f1c1';
                font-family : 'Font Awesome 5 Free';
                font-weight: 300;
                font-size: 16px;
                margin-left: 15px;
                @include SP{
                    font-size: 16px;
                };
            }

        }
        p {
            padding-right: 5px;
            margin: 10px 0 20px;
            text-align: justify;
            line-height: 1.571428571em;
            @include SP{
                font-size: 12px;
                letter-spacing: 0;
                line-height: 1.75em;
                padding-right: 0;
                margin: 15px 0;
            };
        }
        .note {
            display: flex;
            justify-content: space-between;
            @include SP{
                display: block;
            };

            &_name {
                font-size: 16px;
                letter-spacing: 0.04em;
                font-weight: bold;
                @include SP{
                    display: block;
                    width: 100%;
                    font-size: 14px;
                };
            }
            &_date {
                font-size: 14px;
                letter-spacing: 0.04em;
                @include SP{
                    display: block;
                    width: 100%;
                    text-align: right;
                    font-size: 12px;
                    margin-top: 20px;
                };
            }
        }
    }
}
