/*---------------------------------------------
パンくずリスト
---------------------------------------------*/
.breadcrumbs{
    background-color: #eee;
    display: inline-block;
    padding: 10px 20px;
    position: absolute;
    left: 0;
    top: 0;

    @include SP{
		top:-1px;
        padding: 6px 12px 5px;
        background-color: #FFF;
    };
    li{
        float: left;
        font-size: 14px;
        margin-right: 20px;

        &:last-child{
            margin-right: 0;
        }

        @include SP{
            font-size: 10px;
            margin-right: 15px;
        };
        a, span{
            font-family: 'Noto Sans JP', sans-serif;
        }

        > a{
            position: relative;
            &:after{
                content: '>';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -14px;
                @include SP{
                    right: -10px;
                };
            }

            &:hover{
                opacity: .7;
            }
        }
    }
}
