// --- generator ---------------------------------------------------------------

@mixin mq($args...) {
	$media-type: 'only screen';
	$media-type-key: 'media-type';
	$args: keywords($args);
	$expr: '';

	@if map-has-key($args, $media-type-key) {
		$media-type: map-get($args, $media-type-key);
		$args: map-remove($args, $media-type-key);
	}

	@each $key, $value in $args {
		@if $value {
			$expr: "#{$expr} and (#{$key}: #{$value})";
		}
	}

	@media #{$media-type} #{$expr} {
		@content;
	}
}

// --- screen ------------------------------------------------------------------

@mixin screen($min, $max, $orientation: false) {
	@include mq($min-width: $min, $max-width: $max, $orientation: $orientation) {
		@content;
	}
}

@mixin max-screen($max) {
	@include mq($max-width: $max) {
		@content;
	}
}

@mixin min-screen($min) {
	@include mq($min-width: $min) {
		@content;
	}
}


/*---------------------------------------------
Break Point
---------------------------------------------*/
@mixin PC {
  @include min-screen(768px) {
    @content;
  }
}
@mixin SP {
  @include max-screen(767px) {
    @content;
  }
}
.pc {
  @include SP {
    display: none !important;
  }
}
.sp {
  @include PC {
    display: none !important;
  }
}

/*---------------------------------------------
Break Point TypeB
---------------------------------------------*/
@mixin PC2 {
  @include min-screen(1001px) {
    @content;
  }
}
@mixin SP2 {
  @include max-screen(1000px) {
    @content;
  }
}
.pc2 {
  @include SP2 {
    display: none !important;
  }
}
.sp2 {
  @include PC2 {
    display: none !important;
  }
}

/*---------------------------------------------
IE Huck
---------------------------------------------*/
@mixin ie {
  @media all and (-ms-high-contrast:none){
    @content;
  }
}

// 第一引数で基準となる画面幅のフォントサイズ、第二引数で基準となる画面幅を入力
@function get_vw($size, $viewport:1430) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}
