.card {
	&-box {
		display:flex;
		justify-content:space-between;
		flex-wrap:wrap;
	}

	&-item {
		width:calc((100% - 30px) / 2);
		display:flex;
		box-shadow:0 0 10px rgba(0,0,0,0.2);
		>div {
			width:50%;
		}

		&:hover {
			.card-item-right {
				&:after {
					background-color:#235dac;
					color:#FFFFFF;
				}
			}
		}

		@media screen and (max-width: 1000px) {
			width:100%;
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0;
			}
		}

		@media screen and (max-width: 768px)  {
			width:100%;
			flex-direction:column;
			>div {
				width:100%;
			}
		}

		&-left {
			background:#b8b8b8;
			@media screen and (max-width: 768px)  {
				min-height:130px;
			}
		}
		&-right {
			display:flex;
			align-items:center;
			justify-content:center;
			text-align:center;
			flex-direction:column;
			padding:14px 20px 17px;
			position: relative;
			@media screen and (max-width: 768px)  {
				padding:22px 20px 32px;
			}
			&:after {
				content:'\f105';
				font-family: 'Font Awesome 5 Free';
				font-weight: 300;
				font-size: 16px;
				width:20px;
				height:20px;
				border:1px solid #235dac;
				color:#235dac;
				background:#FFFFFF;
				display:flex;
				align-items:center;
				justify-content:center;

				position:absolute;
				bottom:0;
				left:50%;
				transform:translate(-50%, 50%);

				transition:color 0.3s, background-color 0.3s;
			}
		}
	}

	&-title {
		&-01 {
			font-size:20px;
			line-height: 1.2;
			text-align: center;
			font-weight:500;
		}
		&-02 {
			font-size:18px;
			line-height: 1.389;
			text-align: center;
		}
	}

	&-text {
		font-size:12px;
		line-height: 1.4;
		width:100%;
	}
}